<template>
  <div v-if="dashboardLoaded">
    <section v-for="(section, index) in dashboardSections" :key="index">
      <h4 class="mb-1">{{ $t(section.title) }}</h4>
      <hr>
      <b-row>
        <b-col v-for="(statistic, idx) in section.statistics" :key="idx" lg="4" md="6">
          <statistic-card-horizontal :icon="statistic.icon" :color="statistic.color"
            :statistic="(statistic.title === 'Total de cadastros' || statistic.title === 'Usuários ativos' || statistic.title === 'Usuários inativos' || statistic.title === 'Quantidade de planos'
             || statistic.title === 'Tickets de suporte fechados' || statistic.title === 'Tickets de suporte abertos') ? parseInt(statistic.value) : moneyFormat(statistic.value)"
            :statistic-title="$t(statistic.title)" />
        </b-col>
      </b-row>
    </section>
  </div>

  <div v-else class="text-center my-5">
    <b-spinner variant="primary" />
    <p>{{ $t('Carregando dados da dashboard...') }}</p>
  </div>
</template>


<script>
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { mapGetters } from "vuex";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    StatisticCardHorizontal,
    BSpinner,
  },
  data() {
    return {
      dataDashboard: {},
      dashboardSections: [],
      dashboardLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["returnUser", "returnLocale"]),
  },
  async mounted() {
    try {
      const resp = await this.$store.dispatch("dashboardAdmin");
      this.dataDashboard = resp;
      this.initializeDashboardSections();
      this.dashboardLoaded = true;
    } catch (error) {
      console.error("Erro ao carregar a dashboard:", error);
      this.dashboardLoaded = true;
    }
  },
  methods: {
    initializeDashboardSections() {
      this.dashboardSections = [
        {
          title: "Cadastros",
          statistics: [
            {
              icon: "UsersIcon",
              color: "info",
              value: this.dataDashboard.totalUser,
              title: "Total de cadastros",
            },
            {
              icon: "UserCheckIcon",
              color: "success",
              value: this.dataDashboard.activeUser,
              title: "Usuários ativos",
            },
            {
              icon: "UserXIcon",
              color: "danger",
              value: this.dataDashboard.inactiveUser,
              title: "Usuários inativos",
            },
          ],
        },
        {
          title: "Depósitos",
          statistics: [
            {
              icon: "DollarSignIcon",
              color: "success",
              value: this.dataDashboard.totalAmountDeposited,
              title: "Valor total depositado",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "success",
              value: this.dataDashboard.totalAmountDepositedLicenses,
              title: "Valor depositado (licenças)",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "success",
              value: this.dataDashboard.totalAmountDepositedContributions,
              title: "Valor depositado (aportes)",
              isMoney: true,
            },
          ],
        },
        {
          title: "Ativação manual (PIX)",
          statistics: [
            {
              icon: "DollarSignIcon",
              color: "success",
              value: this.dataDashboard.totalAmountSponsoredPIX,
              title: "Valor total de ativações",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "success",
              value: this.dataDashboard.valueInSponsoredLicensePIX,
              title: "Ativação por PIX (licenças)",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "success",
              value: this.dataDashboard.valueInSponsoredContributionPIX,
              title: "Ativação por PIX (aportes)",
              isMoney: true,
            },
          ],
        },
        {
          title: "Patrocínio",
          statistics: [
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.totalAmountSponsored,
              title: "Valor total patrocinado",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.valueInSponsoredLicense,
              title: "Patrocínio (licenças)",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.valueInSponsoredContribution,
              title: "Patrocínio (aportes)",
              isMoney: true,
            },
          ],
        },
        {
          title: "Aporte Emprestado",
          statistics: [
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.amountBorrowed,
              title: "Valor total emprestado",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.loanPaid,
              title: "Empréstimo pago",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.divideRemaining,
              title: "Dívida restante",
              isMoney: true,
            },
          ],
        },
        {
          title: "Reinvestimento",
          statistics: [
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.totalAmountReinvestment,
              title: "Valor total reinvestido",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.valueInReinvestmentLicense,
              title: "Reinvestimento (licenças)",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.valueInReinvestmentContribution,
              title: "Reinvestimento (aportes)",
              isMoney: true,
            },
          ],
        },
        {
          title: "Saques",
          statistics: [
            {
              icon: "DollarSignIcon",
              color: "danger",
              value: this.dataDashboard.totalWithdrawalsMade,
              title: "Total de saques realizados",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "danger",
              value: this.dataDashboard.incomeWithdrawals,
              title: "Saques (rendimento)",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "danger",
              value: this.dataDashboard.bonusWithdrawals,
              title: "Saques (bônus)",
              isMoney: true,
            },
          ],
        },
        {
          title: "Disponível para saque",
          statistics: [
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.totalAvailableForWithdrawal,
              title: "Total disponível para saque",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.availableForBonusWithdrawal,
              title: "Disponível para saque (bônus)",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.availableForWithdrawalIncome,
              title: "Disponível para saque (rendimento)",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.availableForWithdrawalBonusOver20,
              title: "Disponível para saque (bônus) +20",
              isMoney: true,
            },
            {
              icon: "DollarSignIcon",
              color: "warning",
              value: this.dataDashboard.availableForWithdrawalIncomeOver20,
              title: "Disponível para saque (rendimento) +20",
              isMoney: true,
            },
          ],
        },
        {
          title: "Suporte",
          statistics: [
            {
              icon: "ToolIcon",
              color: "success",
              value: this.dataDashboard.supportTicketsClosed,
              title: "Tickets de suporte fechados",
            },
            {
              icon: "ToolIcon",
              color: "danger",
              value: this.dataDashboard.supportTicketsOpen,
              title: "Tickets de suporte abertos",
            },
          ],
        },
        {
          title: "Bônus",
          statistics: this.generateBonusStatistics(),
        },
        {
          title: "Planos",
          statistics: [
            {
              icon: "ClipboardIcon",
              color: "primary",
              value: this.dataDashboard.amountPlans,
              title: "Quantidade de planos",
            },
            {
              icon: "CreditCardIcon",
              color: "info",
              value: this.dataDashboard.totalValueLicense,
              title: "Valor total em licenças",
              isMoney: true,
            },
            {
              icon: "TrendingUpIcon",
              color: "info",
              value: this.dataDashboard.totalValueContribution,
              title: "Valor total em aportes",
              isMoney: true,
            },
            {
              icon: "BarChartIcon",
              color: "info",
              value: this.dataDashboard.totalValueIncome,
              title: "Valor pago de recompensa das operações",
              isMoney: true,
            },
          ],
        },
      ];
    },
    generateBonusStatistics() {
      const bonusLevels = [
        { level: "referenceBonus", title: "Bônus de 1º nível" },
        { level: "unilevelBonusL2", title: "Bônus de 2º nível" },
        { level: "unilevelBonusL3", title: "Bônus de 3º nível" },
        { level: "unilevelBonusL4", title: "Bônus de 4º nível" },
        { level: "unilevelBonusL5", title: "Bônus de 5º nível" },
        { level: "unilevelBonusL6", title: "Bônus de 6º nível" },
        { level: "unilevelBonusL7", title: "Bônus de 7º nível" },
        { level: "unilevelBonusL8", title: "Bônus de 8º nível" },
        { level: "unilevelBonusL9", title: "Bônus de 9º nível" },
        { level: "unilevelBonusL10", title: "Bônus de 10º nível" },
      ];

      const bonusStatistics = [
        {
          icon: "GiftIcon",
          color: "primary",
          value: this.dataDashboard.totalBonus,
          title: "Total em bônus",
          isMoney: true,
        },
      ];

      bonusLevels.forEach((bonus) => {
        bonusStatistics.push({
          icon: "LayersIcon",
          color: "info",
          value: this.dataDashboard[bonus.level],
          title: bonus.title,
          isMoney: true,
        });
      });

      bonusStatistics.push({
        icon: "LayersIcon",
        color: "info",
        value: this.dataDashboard.binaryBonus,
        title: "Bônus binário",
        isMoney: true,
      });

      return bonusStatistics;
    },
    formatStatistic(value, isMoney = false) {
      if (isMoney) {
        return this.moneyFormat(value);
      }
      return value;
    },
  },
};
</script>
